import React, { useState } from 'react';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert';
import { copyToClipboard } from '../../helpers/helpers';
import './Waitlist.scss';

import * as waitlistHelpers from '../../helpers/waitlist_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import ConfirmPrompt from '../General/ConfirmPrompt';
import BrandWaitlistDecisionModal from './BrandWaitlistDecisionModal';
import WaitlistPriorityTagsModal from './WaitlistPriorityTagsModal';
import WaitlistData from './WaitlistData';
import WaitlistUser from './WaitlistUser';
import WaitlistBrand from './WaitlistBrand';
import WaitlistControls from './WaitlistControls';

import { updateWaitlistUser, updateWaitlistBrand, fetchRecommendedRegistrationCodes } from '../../api/waitlist';

const Waitlist = () => {
  const [waitlist, setWaitlist] = useState([]);
  const [brandWaitlist, setBrandWaitlist] = useState([]);
  const [filter, setFilter] = useState('Needs Decision');
  const [curTab, setCurTab] = useState('Users');
  const [filterOpen, setFilterOpen] = useState(false);
  const [accountManagers, setAccountManagers] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const [waitlistPriorityTags, setWaitlistPriorityTags] = useState([]);
  const [activeWaitlistPriorityTags, setActiveWaitlistPriorityTags] = useState([]);

  const setActiveWaitlistPriorityTag = tag => {
    let activeCopy = [...activeWaitlistPriorityTags];

    if (activeCopy.some(t => t.id === tag.id)) activeCopy = activeCopy.filter(t => t.id !== tag.id);
    else activeCopy.push(tag);

    if (activeCopy.length === 0) setFilter('Needs Decision');
    else if (activeCopy.length === 1) setFilter('Priority');

    setActiveWaitlistPriorityTags(activeCopy);
  };

  const [curSearchValue, setCurSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const resetSearch = () => setCurSearchValue('');

  const [brandWaitlistDecisionModalOpen, setBrandWaitlistDecisionModalOpen] = useState(false);
  const [waitlistPriorityTagsModalOpen, setWaitlistPriorityTagsModalOpen] = useState(false);
  const openBrandWaitlistDecisionModal = application => {
    setSelectedApplication(application);
    setBrandWaitlistDecisionModalOpen(true);
  };
  const openWaitlistPriorityTagsModal = () => setWaitlistPriorityTagsModalOpen(true);
  const closeModals = () => {
    setBrandWaitlistDecisionModalOpen(false);
    setWaitlistPriorityTagsModalOpen(false);
  };

  const acceptWaitlistUser = async waitlistUser => {
    let suggestedRegistrationCodes;

    try {
      const suggestedRegistrationCodesResults = await fetchRecommendedRegistrationCodes(waitlistUser.name);
      suggestedRegistrationCodes = suggestedRegistrationCodesResults.suggestedCodes;
    } catch (e) {
      suggestedRegistrationCodes = [];
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='What code would you like to use?'
          subheader={
            <div>
              <div>Select a code from the list below or fill in your own custom code to send to the user.</div>
              <div className='suggested-codes'>
                {suggestedRegistrationCodes.map(code => {
                  return (
                    <div key={code} className='suggested-code' onClick={() => copyToClipboard(code, `${code} copied!`)}>
                      {code}
                      <FontAwesomeIcon icon={faCopy} />
                    </div>
                  );
                })}
              </div>
            </div>
          }
          preloaded={suggestedRegistrationCodes.length ? suggestedRegistrationCodes[0] : ''}
          placeholder={`NAME-2021`}
          isSingleLine
          onCancel={onClose}
          onCustom={code => {
            updateWaitlistUser(waitlistUser, { code, handledOffline: true, isAccepted: true, isRejected: false }).then(
              resp => {
                setWaitlist(waitlist.map(u => (u.id === resp.id ? resp : u)));
                onClose();
                cogoToast.success(`Successfully created code ${code}, please send them an email at ${waitlistUser.email}.`);
              },
              error => cogoToast.error(error)
            );
          }}
          customBtnDisplay='Handle Offline'
          submitBtnDisplay='Send Code By Email'
          onSubmit={code => {
            updateWaitlistUser(waitlistUser, { code, isAccepted: true, isRejected: false }).then(
              resp => {
                setWaitlist(waitlist.map(u => (u.id === resp.id ? resp : u)));
                onClose();
                cogoToast.success(`Successfully created code ${code} and sent automated email to ${waitlistUser.email}.`);
              },
              error => cogoToast.error(error)
            );
          }}
        />
      )
    });
  };

  const rejectWaitlistUser = async waitlistUser => {
    updateWaitlistUser(waitlistUser, { isAccepted: false, isRejected: true }).then(
      resp => setWaitlist(waitlist.map(u => (u.id === resp.id ? resp : u))),
      error => cogoToast.error(error)
    );
  };

  const acceptBrand = async props => {
    const { waitlistBrand, AccountRepresentitive_id, manual_outreach, email_body } = props;
    await updateWaitlistBrand(waitlistBrand, { manual_outreach, AccountRepresentitive_id, accepted: true, email_body })
      .then(() => {
        setBrandWaitlist(brandWaitlist.map(u => (u.id === waitlistBrand.id ? { ...waitlistBrand, isAccepted: true } : u)));
        cogoToast.success(`Successfully accepted ${waitlistBrand.name}.`);
      })
      .catch(error => {
        cogoToast.error('Error accepting brand.');
        console.error(error);
      });
  };

  const rejectBrand = async props => {
    const { waitlistBrand } = props;
    updateWaitlistBrand(waitlistBrand, { accepted: false }).then(
      () => {
        setBrandWaitlist(brandWaitlist.map(u => (u.id === waitlistBrand.id ? { ...waitlistBrand, isRejected: true } : u)));
        cogoToast.success(`Successfully rejected ${waitlistBrand.name}.`);
      },
      error => {
        cogoToast.error('Error rejecting brand.');
        console.error(error);
      }
    );
  };

  const filterOptions = [
    { label: 'Needs Decision', filter: waitlist_row => waitlistHelpers.waitlistUserNeedsDecision(waitlist_row) },
    { label: 'Accepted', filter: waitlist_row => waitlistHelpers.waitlistUserIsAccepted(waitlist_row) },
    { label: 'Rejected', filter: waitlist_row => waitlistHelpers.waitlistUserIsRejected(waitlist_row) },
    { label: 'Priority', filter: waitlist_row => waitlistHelpers.waitlistUserHasPriority(waitlist_row, activeWaitlistPriorityTags) },
    { label: 'All', filter: () => true }
  ];

  const selectedFilter = filterOptions.find(f => f.label === filter) || filterOptions[0];
  const resetFilters = () => setFilter('Needs Decision');
  const changeFilter = option => setFilter(option.label);

  // log the filter states to get a better understanding of the data
  console.log({
    waitlist,
    waitlistFiltered: waitlist.filter(selectedFilter.filter)
  });

  return (
    <div className='waitlist-outer-container'>
      <WaitlistData
        setWaitlist={setWaitlist}
        setBrandWaitlist={setBrandWaitlist}
        setWaitlistPriorityTags={setWaitlistPriorityTags}
        setAccountManagers={setAccountManagers}
        curSearchValue={curSearchValue}
        setSearchResults={setSearchResults}
        setIsSearching={setIsSearching}
        curTab={curTab}
        setFilter={setFilter}
      />

      {waitlistPriorityTagsModalOpen && (
        <WaitlistPriorityTagsModal
          waitlistPriorityTags={waitlistPriorityTags}
          setWaitlistPriorityTags={setWaitlistPriorityTags}
          close={closeModals}
        />
      )}
      <div className='waitlist-inner-container'>
        {brandWaitlistDecisionModalOpen && (
          <BrandWaitlistDecisionModal
            close={closeModals}
            selectedApplication={selectedApplication}
            accountManagers={accountManagers}
            acceptBrand={acceptBrand}
            rejectBrand={rejectBrand}
          />
        )}
        <div className='waitlist-title-container'>
          <h1>Waitlist</h1>

          <WaitlistControls
            changeFilter={changeFilter}
            selectedFilter={selectedFilter}
            setCurTab={setCurTab}
            curTab={curTab}
            filterOpen={filterOpen}
            resetSearch={resetSearch}
            resetFilters={resetFilters}
            filterOptions={filterOptions}
            setFilterOpen={setFilterOpen}
            curSearchValue={curSearchValue}
            setCurSearchValue={setCurSearchValue}
          />

          {curTab === 'Users' && (
            <div className='waitlist-priority-tags'>
              <div className='waitlist-priority-tags-title'>Priority Tags</div>

              <div className='waitlist-priority-tags-container'>
                {waitlistPriorityTags.map(priorityTag => {
                  const isActive = activeWaitlistPriorityTags.some(t => t.id === priorityTag.id);

                  return (
                    <div
                      key={priorityTag.id}
                      className={cn('waitlist-priority-tag', { isActive })}
                      onClick={() => setActiveWaitlistPriorityTag(priorityTag)}
                    >
                      {priorityTag.tag}
                    </div>
                  );
                })}

                <div
                  className='waitlist-priority-tag add'
                  onClick={() => {
                    if (activeWaitlistPriorityTags.length !== waitlistPriorityTags.length) {
                      setActiveWaitlistPriorityTags([...waitlistPriorityTags]);
                      setFilter('Priority');
                    } else {
                      setActiveWaitlistPriorityTags([]);
                      setFilter('Needs Decision');
                    }
                  }}
                >
                  {activeWaitlistPriorityTags.length !== waitlistPriorityTags.length ? 'Select All' : 'Clear All'}
                </div>
                <div className='waitlist-priority-tag add' onClick={openWaitlistPriorityTagsModal}>
                  Add Priority Tag
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={cn('results-container', { isSearching })}>
          {curTab === 'Users' ? (
            <div className='waitlist-user row header'>
              <div className='cell'>Created At</div>
              <div className='cell md'>Name</div>
              <div className='cell'>Email</div>
              <div className='cell'>Social</div>
              <div className='cell xl'>Pitch</div>
              <div className='cell lg center'>Status</div>
              <div className='cell md'>Notes</div>
              <div className='cell md'>Location</div>
            </div>
          ) : (
            <div className='waitlist-user row header'>
              <div className='cell'>Created At</div>
              <div className='cell md'>Name</div>
              <div className='cell'>Domain</div>
              <div
                className={cn('cell', {
                  xl: selectedFilter.label === 'Needs Decision',
                  md: selectedFilter.label !== 'Needs Decision'
                })}
              >
                {selectedFilter.label === 'Needs Decision' ? 'Pitch' : 'Account Rep'}
              </div>
              <div className='cell lg center'>Status</div>
            </div>
          )}
          {searchResults.length && curTab === 'Brands'
            ? searchResults.map((waitlistBrand, idx) => {
                return (
                  <WaitlistBrand
                    openBrandWaitlistDecisionModal={openBrandWaitlistDecisionModal}
                    selectedFilter={selectedFilter}
                    waitlistBrand={waitlistBrand}
                    accountManagers={accountManagers}
                    key={`waitlist-brand-${waitlistBrand.id}`}
                  />
                );
              })
            : searchResults.length && curTab === 'Users'
            ? searchResults.map((waitlistUser, idx) => {
                return (
                  <WaitlistUser
                    waitlist={waitlist}
                    setWaitlist={setWaitlist}
                    waitlistUser={waitlistUser}
                    selectedFilter={selectedFilter}
                    acceptWaitlistUser={acceptWaitlistUser}
                    rejectWaitlistUser={rejectWaitlistUser}
                    key={`waitlist-user-${waitlistUser.id}`}
                  />
                );
              })
            : curTab === 'Brands'
            ? brandWaitlist.filter(selectedFilter.filter).map((waitlistBrand, idx) => {
                return (
                  <WaitlistBrand
                    openBrandWaitlistDecisionModal={openBrandWaitlistDecisionModal}
                    selectedFilter={selectedFilter}
                    waitlistBrand={waitlistBrand}
                    accountManagers={accountManagers}
                    key={`waitlist-brand-${waitlistBrand.id}`}
                  />
                );
              })
            : waitlist.filter(selectedFilter.filter).map((waitlistUser, idx) => {
                return (
                  <WaitlistUser
                    waitlist={waitlist}
                    setWaitlist={setWaitlist}
                    waitlistUser={waitlistUser}
                    selectedFilter={selectedFilter}
                    acceptWaitlistUser={acceptWaitlistUser}
                    rejectWaitlistUser={rejectWaitlistUser}
                    key={`waitlist-user-${waitlistUser.id}`}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

Waitlist.propTypes = {};

export default Waitlist;
