import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faEyeSlash, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import _ from 'lodash';

import './NewsletterTabs.scss';

import { createNewsletterTab, editNewsletterTab, deleteNewsletterTab } from '../../api/newsletters';
import { getGMTTime } from '../../helpers/formatting';

const NewsletterTabs = props => {
  const { setActiveTab, setIsEditingTabs, isEditingTabs, activeTab, newsletter, syncNewsletter } = props;
  const tabs = _.orderBy(newsletter.tabs, 'sortOrderRank', 'asc');

  const toggleEditing = () => setIsEditingTabs(!isEditingTabs);

  const addNewTab = async () => {
    const title = window.prompt('Enter a name for the new tab');
    if (title) {
      await createNewsletterTab({ title, Newsletter_id: newsletter.id });
      syncNewsletter();
    }
  };

  return (
    <div className='newsletter-tabs'>
      {tabs.map((tab, idx) => {
        const { id, title, isHidden } = tab;
        const select = () => !isEditingTabs && setActiveTab(isActive ? null : tab);
        const prevTab = tabs[idx - 1];
        const nextTab = tabs[idx + 1];
        const isActive = activeTab && activeTab.id === id;

        const moveLeft = () => {
          if (prevTab) {
            const prevPrevTab = tabs[idx - 2];
            const newRank = prevPrevTab ? (prevTab.sortOrderRank + prevPrevTab.sortOrderRank) / 2 : prevTab.sortOrderRank / 2;
            editNewsletterTab(tab, { sortOrderRank: newRank });
            syncNewsletter();
          }
        };
        const moveRight = () => {
          if (nextTab) {
            const nextNextTab = tabs[idx + 2];
            const newRank = nextNextTab ? (nextTab.sortOrderRank + nextNextTab.sortOrderRank) / 2 : getGMTTime();
            editNewsletterTab(tab, { sortOrderRank: newRank });
            syncNewsletter();
          }
        };
        const toggleVisibility = async () => {
          await editNewsletterTab(tab, { isHidden: !isHidden });
          syncNewsletter();
        };
        const edit = async () => {
          const newTitle = window.prompt('Enter a name for the new tab', tab.title);
          if (newTitle) {
            await editNewsletterTab(tab, { title: newTitle });
            syncNewsletter();
          }
        };
        const remove = async () => {
          const confirm = window.confirm('Are you sure you want to delete this tab?');
          if (confirm) {
            await deleteNewsletterTab(tab);
            syncNewsletter();
          }
        };
        return (
          <div key={id} onClick={select} className={cn('tab-container', { active: isActive, hidden: isHidden })}>
            <div className={isActive ? 'tab active' : 'tab'}>
              <div className='title-container'>
                <div className='title'>{title}</div>
              </div>
              {isEditingTabs && (
                <div className='edit-actions'>
                  {prevTab && (
                    <div onClick={moveLeft} className='action'>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                  )}
                  {nextTab && (
                    <div onClick={moveRight} className='action'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  )}
                  <div onClick={toggleVisibility} className='action'>
                    <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} />
                  </div>
                  <div onClick={edit} className='action'>
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                  <div onClick={remove} className='action'>
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className='tab-actions'>
        {isEditingTabs ? (
          <>
            <div onClick={addNewTab} className='action'>
              <div className={'tab'}>ADD NEW</div>
            </div>
            <div onClick={toggleEditing} className='action'>
              <div className={'tab'}>DONE</div>
            </div>
          </>
        ) : (
          <div onClick={toggleEditing} className='action'>
            <div className={'tab'}>EDIT</div>
          </div>
        )}
      </div>
    </div>
  );
};

NewsletterTabs.propTypes = {
  newsletter: PropTypes.object.isRequired,
  activeTab: PropTypes.object,
  setActiveTab: PropTypes.func.isRequired,
  syncNewsletter: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};

export default NewsletterTabs;
