//Force
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import './App.css';

import { configureAxios } from './Auth';

import Analytics from './components/Analytics/Analytics';
import Brands from './components/Brands/Brands';
import BrandReports from './components/BrandReports/BrandReports';
import Chat from './components/Chat/Chat';
import Consults from './components/Consults/Consults';
import Login from './components/Login/Login';
import Pins from './components/Pins/Pins';
import Products from './components/Products/Products';
import Hierarchy from './components/Hierarchy/Hierarchy';
import MainContainer from './components/MainContainer/MainContainer';
import Newsletters from './components/Newsletters/Newsletters';
import Payments from './components/Payments/Payments';
import Scrapers from './components/Scrapers/Scrapers';
import Contracts from './components/Contracts/Contracts';
import ContractTaskTemplates from './components/Contracts/ContractTaskTemplates';
import Subscriptions from './components/Subscriptions/Subscriptions';
import Users from './components/Users/Users';
import Waitlist from './components/Waitlist/Waitlist';
import Merchants from './components/Merchants/Merchants';
import Invoices from './components/Invoices/Invoices';
import GrowthScraper from './components/GrowthScraper/GrowthScraper';
import ML from './components/ML/ML';
import Concierge from './components/Concierge/Concierge';
import MlTraining from './components/MlTraining/MlTraining';
import Prompts from './components/Prompts/Prompts';
import LookbookOrders from './components/LookbookOrders/LookbookOrders';

import Logout from './components/Redirects/Logout';
import { syncCatalogHierarchy } from './actions/catalogActions';

class App extends Component {
  componentDidMount = () => {
    Auth.currentSession()
      .then(res => {
        configureAxios(res);
        this.props.syncCatalogHierarchy();
        this.setState({ isLoggedIn: true, authLoading: false });
      })
      .catch(err => {
        console.log('App Auth:', err);
        this.setState({ isLoggedIn: false, authLoading: false });
      });
  };

  state = {
    authLoading: true,
    isLoggedIn: false
  };

  render() {
    const { authLoading, isLoggedIn } = this.state;
    if (authLoading) return null;

    if (!isLoggedIn) {
      return <Login completeLogin={() => this.setState({ isLoggedIn: true })} />;
    }

    const PublicRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        component={props => (
          <MainContainer>
            <Component {...props} />
          </MainContainer>
        )}
      />
    );

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path='/'
            exact
            component={() => {
              return <Redirect to={{ pathname: '/products' }} />;
            }}
          />
          <PublicRoute path='/products' component={() => <Products />} />
          <PublicRoute path='/hierarchy' component={() => <Hierarchy />} />
          {/* 
            Add back ONLY if backend is optimized, else it will crash the server
            <PublicRoute path='/health' component={() => <Health />} /> 
          */}
          <PublicRoute path='/pins' component={() => <Pins />} />
          <PublicRoute path='/brands' component={() => <Brands />} />
          <PublicRoute path='/brandreports' component={() => <BrandReports />} />
          <PublicRoute path='/invoices' component={() => <Invoices />} />
          <PublicRoute path='/payments' component={() => <Payments />} />
          <PublicRoute path='/consults' component={() => <Consults />} />
          <PublicRoute path='/scrapers' component={() => <Scrapers />} />
          <PublicRoute path='/contract-task-templates' component={() => <ContractTaskTemplates />} />
          <PublicRoute path='/subscriptions' component={() => <Subscriptions />} />
          <PublicRoute path='/users' component={() => <Users />} />
          <PublicRoute path='/waitlist' component={() => <Waitlist />} />
          <PublicRoute path='/merchants' component={() => <Merchants />} />
          <PublicRoute path='/collaborations' component={() => <Contracts />} />
          <PublicRoute path='/tasks' component={() => <Contracts />} />
          <PublicRoute path='/analytics' component={() => <Analytics />} />
          <PublicRoute path='/chat' component={() => <Chat />} />
          <PublicRoute path='/growthscraper' component={() => <GrowthScraper />} />
          <PublicRoute path='/newsletters' component={() => <Newsletters />} />
          <PublicRoute path='/ml' component={() => <ML />} />
          <PublicRoute path='/concierge' component={() => <Concierge />} />
          <PublicRoute path='/ml-training' component={() => <MlTraining />} />
          <PublicRoute path='/prompts' component={() => <Prompts />} />
          <PublicRoute path='/lookbookorders' component={() => <LookbookOrders />} />

          <PublicRoute exact path='/logout' component={() => <Logout />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  const { hierarchy, router } = state;
  return { hierarchy, router };
};

export default connect(mapStateToProps, {
  syncCatalogHierarchy
})(App);
