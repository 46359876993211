import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import Mousetrap from 'mousetrap';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './Products.scss';

import ProductCatalogHierarchyView from './elements/ProductCatalogHierarchyView';
import ProductsSearchView from './elements/ProductsSearchView';
import ProductEditView from './elements/ProductEditView';
import ProductMatchView from './elements/ProductMatchView';
import QueueTickets from './elements/QueueTickets';

import { getProduct, addProduct, updateProduct, updateVariant, deleteProduct, updateProductTags } from '../../api/products';
import { updatePin, getPinsFromUrl } from '../../api/pins';
import { syncCatalogHierarchy } from '../../actions/catalogActions';
import { getVariantsQueue, getPinsQueue, getPinsQueueRemaining } from '../../actions/queueActions';

class Products extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    syncCatalogHierarchy: PropTypes.func.isRequired,
    getVariantsQueue: PropTypes.func.isRequired,
    getPinsQueue: PropTypes.func.isRequired,
    getPinsQueueRemaining: PropTypes.func.isRequired
  };

  state = {
    selectedProduct: null,
    selectedTicket: null,
    activeFilters: {
      department: null,
      category: null,
      brand: null,
      tag: null
    },
    tickets: [],
    queueSearchVal: '',
    queueSearchDomainVal: '',
    queueSearchBrandVal: '',
    queueSearchRetailerVal: '',
    queueSortVal: 'createdAt',
    inMatchingMode: true,
    isLoadingNumPinsRemaining: false
  };

  componentWillUnmount() {
    Mousetrap.unbind('m');
  }
  componentDidMount() {
    Mousetrap.bind('m', () => this.setState({ inMatchingMode: !this.state.inMatchingMode }));
  }

  syncVariantsQueue = () =>
    new Promise(resolve => {
      this.props
        .getVariantsQueue({
          query: this.state.queueSearchVal,
          queryDomain: this.state.queueSearchDomainVal,
          queryBrand: this.state.queueSearchBrandVal,
          queryRetailer: this.state.queueSearchRetailerVal,
          basicUnmatchedOnly: this.state.inMatchingMode,
          adminUnmatchedOnly: !this.state.inMatchingMode,
          orderBy: this.state.queueSortVal
        })
        .then(resolve);
    });

  syncPinsQueue = async options => {
    const { shouldSelectTicket = false } = options || {};

    await this.props.getPinsQueue({
      query: this.state.queueSearchVal,
      queryDomain: this.state.queueSearchDomainVal,
      queryBrand: this.state.queueSearchBrandVal,
      queryRetailer: this.state.queueSearchRetailerVal,
      orderBy: this.state.queueSortVal
    });

    if (shouldSelectTicket) {
      const nextTicket = _.get(this.props, 'queue.pinTickets.0', null);
      this.setState({ selectedTicket: nextTicket });
    }
  };

  syncNumPinsRemaining = async () => {
    this.setState({ isLoadingNumPinsRemaining: true });
    await this.props.getPinsQueueRemaining();
    this.setState({ isLoadingNumPinsRemaining: false });
  };

  selectProduct = selectedProduct => {
    this.setState({ selectedProduct });
  };

  matchSelectedTicket = product => {
    const { selectedTicket } = this.state;
    updateVariant(selectedTicket.id, { Product_id: product.id }).then(
      newVariant => {
        this.syncVariantsQueue().then(resp => {
          const nextTicket = _.get(this.props, 'queue.variantTickets.0', null);
          this.setState({ selectedTicket: nextTicket });
        });
        cogoToast.success('Successfully matched.', { hideAfter: 1 });
        this.checkIfUnmatchedPinsCanBeMatchedByRecentVariantMatch(newVariant);
      },
      err => {
        cogoToast.error('There was an issue with this match.');
      }
    );
  };

  updateProduct = rawData =>
    new Promise(async (resolve, reject) => {
      const data = { ...rawData };
      delete data.tags;
      try {
        await updateProductTags(this.state.selectedProduct.id, _.map(rawData.tags, 'id'));
        await updateProduct(this.state.selectedProduct.id, data);
        cogoToast.success('Successfully updated product', { hideAfter: 0.5 });
        this.forceSearchRefresh();
        this.props.syncCatalogHierarchy();
        resolve();
      } catch (error) {
        cogoToast.error(JSON.stringify(error));
        reject();
      }
    });

  addProduct = rawData =>
    new Promise(async (resolve, reject) => {
      const data = { ...rawData };
      delete data.tags;

      try {
        const product = await addProduct(data);
        await updateProductTags(product.id, _.map(rawData.tags, 'id'));
        cogoToast.success('Successfully updated product', { hideAfter: 0.5 });
        this.forceSearchRefresh();
        this.props.syncCatalogHierarchy();
        resolve(product);
      } catch (error) {
        cogoToast.error(JSON.stringify(error));
        reject();
      }
    });

  deleteProduct = () =>
    new Promise((resolve, reject) => {
      deleteProduct(this.state.selectedProduct.id).then(
        resp => {
          this.forceSearchRefresh();
          this.props.syncCatalogHierarchy();
          resolve();
        },
        err => {
          console.error(err);
          cogoToast.error(JSON.stringify(err));
          reject();
        }
      );
    });

  checkIfUnmatchedPinsCanBeMatchedByRecentVariantMatch = async variant => {
    /*
      Once we match a variant to a product, we want to check the queue of unmatched pins to see if we
      can find a direct URL match and auto-match those. This will speed up the catalog development as
      we add new categories based on the pins that have been added.
    */
    const allPinsMatchingVariantUrl = await getPinsFromUrl(variant.url);

    const unmatchedPins = allPinsMatchingVariantUrl.pins.filter(pin => !pin.Product_id);

    if (unmatchedPins.length) {
      confirmAlert({
        title: 'Found Pin Matches',
        message: `We found ${unmatchedPins.length} pin${
          unmatchedPins.length === 1 ? '' : 's'
        } that match that URL, would you like to automatically match them?`,
        buttons: [
          { label: 'No', className: 'cancel', onClick: () => {} },
          {
            label: 'Yes',
            onClick: async () => {
              cogoToast.info(`Beginning Auto-Matching of ${unmatchedPins.length} pin${unmatchedPins.length === 1 ? '' : 's'}`);
              for (let i = 0; i < unmatchedPins.length; i++) {
                const newPin = unmatchedPins[i];
                await updatePin(newPin.id, { Product_id: variant.Product_id });
              }
              cogoToast.success(`Completed Auto-Matching!`);
              this.syncPinsQueue();
            }
          }
        ]
      });
    }
  };

  render() {
    const { catalog, queue } = this.props;
    const { selectedProduct, selectedTicket, activeFilters, inMatchingMode } = this.state;
    return (
      <div className='products-outer-container'>
        <ProductCatalogHierarchyView
          catalog={catalog}
          setActiveFilters={newFilters => this.setState({ activeFilters: { ...activeFilters, ...newFilters } })}
          activeFilters={activeFilters}
        />
        <ProductsSearchView
          catalog={catalog}
          selectProduct={this.selectProduct}
          matchSelectedTicket={this.matchSelectedTicket}
          selectedProduct={selectedProduct}
          selectedTicket={selectedTicket}
          inMatchingMode={inMatchingMode}
          setSearchRefresh={fn => {
            this.forceSearchRefresh = fn;
          }}
          activeFilters={activeFilters}
        />
        {inMatchingMode ? (
          <ProductMatchView
            queue={queue}
            searchVal={this.state.queueSearchVal}
            searchDomainVal={this.state.queueSearchDomainVal}
            updateSearchVal={newVal => this.setState({ queueSearchVal: newVal })}
            updateSearchDomainVal={newVal => this.setState({ queueSearchDomainVal: newVal })}
            updateSearchBrandVal={newVal => this.setState({ queueSearchBrandVal: newVal })}
            updateSearchRetailerVal={newVal => this.setState({ queueSearchRetailerVal: newVal })}
            queueSearchVal={this.state.queueSearchVal}
            queueSearchDomainVal={this.state.queueSearchDomainVal}
            queueSearchBrandVal={this.state.queueSearchBrandVal}
            queueSearchRetailerVal={this.state.queueSearchRetailerVal}
            selectedTicket={selectedTicket}
            performSearch={this.forceSearchRefresh}
            syncVariantsQueue={this.syncVariantsQueue}
            syncPinsQueue={this.syncPinsQueue}
            selectTicket={ticket => this.setState({ selectedTicket: selectedTicket && selectedTicket.id === ticket.id ? null : ticket })}
          />
        ) : (
          <ProductEditView
            catalog={catalog}
            getProduct={getProduct}
            selectedProduct={selectedProduct}
            selectedTicket={selectedTicket}
            selectProduct={this.selectProduct}
            addProduct={this.addProduct}
            deleteProduct={this.deleteProduct}
            updateProduct={this.updateProduct}
            syncVariantsQueue={this.syncVariantsQueue}
            syncPinsQueue={this.syncPinsQueue}
            syncNumPinsRemaining={this.syncNumPinsRemaining}
            performSearch={this.forceSearchRefresh}
            checkIfUnmatchedPinsCanBeMatchedByRecentVariantMatch={this.checkIfUnmatchedPinsCanBeMatchedByRecentVariantMatch}
          />
        )}
        {!_.isEmpty(queue) && !inMatchingMode && (
          <QueueTickets
            queue={queue}
            searchVal={this.state.queueSearchVal}
            searchDomainVal={this.state.queueSearchDomainVal}
            queueSortVal={this.state.queueSortVal}
            updateSearchVal={newVal => this.setState({ queueSearchVal: newVal })}
            updateSearchDomainVal={newVal => this.setState({ queueSearchDomainVal: newVal })}
            updateQueueSortVal={newVal => this.setState({ queueSortVal: newVal })}
            selectedTicket={selectedTicket}
            syncVariantsQueue={this.syncVariantsQueue}
            syncPinsQueue={this.syncPinsQueue}
            selectTicket={ticket => this.setState({ selectedTicket: ticket })}
            isLoadingNumPinsRemaining={this.state.isLoadingNumPinsRemaining}
            syncNumPinsRemaining={this.syncNumPinsRemaining}
            selectedProduct={selectedProduct}
          />
        )}
        <div className='toggle-match-mode-btn' onClick={() => this.setState({ inMatchingMode: !inMatchingMode })}>
          {inMatchingMode ? 'Match Mode (press "m")' : 'Admin Mode'}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { catalog, queue } = state;
  return { catalog, queue };
};

export default connect(mapStateToProps, {
  syncCatalogHierarchy,
  getVariantsQueue,
  getPinsQueue,
  getPinsQueueRemaining
})(Products);
