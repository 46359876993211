import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import './BrandsNewRegistrationCode.scss';

import Modal from '../General/Modal';

import { getSlugifiedRegistrationCode } from '../../helpers/brand_helpers';

const BrandsNewRegistrationCode = props => {
  const { addRegistrationCode, getBrandRegistrationCodes, closeModal } = props;
  const [brandName, setBrandName] = useState('');
  const [brandWebsite, setBrandWebsite] = useState('');
  const [code, setCode] = useState('');
  const [brandLogo, setBrandLogo] = useState('');

  const addNewCode = async () => {
    addRegistrationCode({ code, brandName, brandWebsite, brandLogo, useLimit: 5 }).then(
      resp => {
        getBrandRegistrationCodes();
        cogoToast.success(`Added new code ${code}!`);
        props.closeModal();
      },
      err => cogoToast.error(_.get(err, 'data.error.sqlMessage', `Error adding new code ${code}!`))
    );
  };
  return (
    <Modal close={closeModal}>
      <div className='brand-new-registration-code-outer-container'>
        <h3>Add New Registration Code</h3>
        <div className='input-group'>
          <div className='title'>Brand Name</div>
          <div className='subtitle'>Please make sure this matches the brand name in the catalog exactly.</div>
          <input placeholder='' value={brandName} onChange={({ target }) => setBrandName(target.value)} />
        </div>
        <div className='input-group'>
          <div className='title'>Brand Website</div>
          <div className='subtitle'>The brand home page, format: https://brandname.com</div>
          <input placeholder='' value={brandWebsite} onChange={({ target }) => setBrandWebsite(target.value)} />
        </div>
        <div className='input-group'>
          <div className='title'>Invitation Code</div>
          <div className='subtitle'>
            You will send this code to them to sign up.{' '}
            {brandName.length ? `Do not use ${getSlugifiedRegistrationCode(brandName)}, that will be used by their audience.` : ''}
          </div>
          <input placeholder='WELCOME-BRAND' value={code} onChange={({ target }) => setCode(target.value)} />
        </div>
        <div className='input-group'>
          <div className='title'>Logo URL</div>
          <div className='subtitle'>Only .png and .jpg allowed</div>
          <input placeholder='' value={brandLogo} onChange={({ target }) => setBrandLogo(target.value)} />
        </div>
        <div className='submit-btn' onClick={addNewCode}>
          SETUP
        </div>
      </div>
    </Modal>
  );
};

BrandsNewRegistrationCode.propTypes = {
  closeModal: PropTypes.func.isRequired,
  addRegistrationCode: PropTypes.func.isRequired,
  getBrandRegistrationCodes: PropTypes.func.isRequired
};

export default BrandsNewRegistrationCode;
