import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';

import instagramIcon from '../../../static/images/social_icons/instagram_dark.png';
import tiktokIcon from '../../../static/images/social_icons/tiktok_dark.png';
import youtubeIcon from '../../../static/images/social_icons/youtube_dark.png';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import './ConciergeModalSidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { copyToClipboard } from '../../../helpers/helpers';
import Switch from 'react-switch';
import cogoToast from 'cogo-toast';
import { splitDbSocialLinksIntoIndividualLinks } from '../../../helpers/user_helpers';
import ConciergeComment from '../ConciergeSidebar/ConciergeComment';

const ConciergeModalSidebar = props => {
  const { accountManagers, talent, setTimePeriod, timePeriod, updateComment, updateUser, updateUserAddress } = props;
  const {
    image,
    name,
    id,
    username,
    email,
    AccountManager_id,
    notes,
    phone,
    address,
    isArchived,
    createdAt,
    lastLoggedIn,
    currentAppVersion,
    code: registrationCode,
    totalVolume
  } = talent;
  const { hasSnapshop, hasMobileApp, hasInstagram, hasTiktok, hasYoutube, social_links } = talent;
  // console.info({ talent });

  const splitSocialLinks = splitDbSocialLinksIntoIndividualLinks(social_links);
  const { youtube_url, instagram_url, tiktok_url } = splitSocialLinks;
  // console.info({ talent });

  const [editProfileMode, setEditProfileMode] = useState(false);

  const createAccountManagerSelectOptions = () => {
    if (!accountManagers) return [];

    const accountManagerOptions = [{ label: 'None', value: null }];
    accountManagers.forEach(manager => accountManagerOptions.push({ label: manager.name, value: manager.id, manager }));
    return accountManagerOptions;
  };

  const setNewAccountManager = AccountManager => updateUser({ AccountManager_id: AccountManager.value });
  const updateGroupingMethod = groupingMethod => setTimePeriod({ ...timePeriod, groupingMethod });
  const updateStartDate = startDate => setTimePeriod({ ...timePeriod, startDate: moment(startDate).format('YYYY-MM-DD') });
  const updateEndDate = endDate => setTimePeriod({ ...timePeriod, endDate: moment(endDate).format('YYYY-MM-DD') });

  const updateArchiveStatus = () => updateUser({ isArchived: !isArchived });

  const updatePhoneNumer = () => {
    const newPhone = window.prompt('Update the phone number.', phone);
    if (!newPhone) return;
    updateUserAddress({ phone: newPhone });
  };

  const updateAdresss = () => {
    const newAddress = window.prompt('Update the address.', address);
    if (!newAddress) return;
    updateUserAddress({ address: newAddress });
  };

  const createComment = type => props.createComment(id, type);

  return (
    <div className='concierge-modal-sidebar-outer'>
      <div className='concierge-sidebar-container'>
        <div className='image-container'>
          {image ? <img className='image' src={image} alt='profile_picture' /> : <div className='image-replacement'>{name.split(' ')[0][0]}</div>}
        </div>
      </div>
      <div className='concierge-sidebar-container'>
        <div className='controls-container'>
          <div className='controls-container-header'>Account Manager</div>
          <Select
            className='select'
            placeholder='Account Manager'
            value={createAccountManagerSelectOptions().find(manager => manager.value === parseInt(AccountManager_id))}
            onChange={setNewAccountManager}
            options={createAccountManagerSelectOptions()}
          />
        </div>
        <div className='heading'>{name}</div>
        <div className='subheading'>
          <div>
            <a
              href={`https://shopmy.us/${username}`}
              onClick={e => e.stopPropagation()}
              target='_blank'
              rel='noreferrer'
              className='small-text center-text'
            >
              https://shopmy.us/{username}
            </a>
            <div>
              <span>{hasMobileApp ? 'Has App' : 'No App'}</span>&nbsp;•&nbsp;<span>{hasSnapshop ? 'Has Snapshop' : 'No Snapshop'}</span>
            </div>
            <div>Current App Version: {currentAppVersion || 'N/A'}</div>
            <div>Code: {registrationCode || 'None Used'}</div>
            <div>Last Logged In: {moment(lastLoggedIn).isValid() ? moment(lastLoggedIn).format('MMM DD, YYYY') : 'N/A'}</div>
            <div>Total Volume: ${totalVolume || '0'}</div>
            <div>
              <i>Date Joined: {moment(createdAt).format('MMM DD, YYYY')}</i>
            </div>
            <div className='social-link-container'>
              {instagram_url && (
                <a href={instagram_url} className='social-link' target='_blank' rel='noreferrer' onClick={e => e.stopPropagation()}>
                  <img src={instagramIcon} alt='instagram' />
                </a>
              )}
              {tiktok_url && (
                <a href={tiktok_url} className='social-link' target='_blank' rel='noreferrer' onClick={e => e.stopPropagation()}>
                  <img src={tiktokIcon} alt='tiktok' />
                </a>
              )}
              {youtube_url && (
                <a href={youtube_url} className='social-link' target='_blank' rel='noreferrer' onClick={e => e.stopPropagation()}>
                  <img src={youtubeIcon} alt='youtube' />
                </a>
              )}
            </div>
          </div>

          <div>
            <div>Instagram: {hasInstagram ? 'Linked' : 'Not Linked'}</div>
            <div>Tiktok: {hasTiktok ? 'Linked' : 'Not Linked'}</div>
            <div>Youtube: {hasYoutube ? 'Linked' : 'Not Linked'}</div>
          </div>

          <div>
            <div className='clickable' onClick={() => copyToClipboard(id, true)}>
              ID: {id}
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <div className='clickable' onClick={() => copyToClipboard(email, true)}>
              Email: {email}
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <div className='clickable' onClick={() => editProfileMode && updateArchiveStatus()}>
              <span style={{ marginRight: '5px' }}>Status: {isArchived ? 'Archived' : 'Active'}</span>
              {editProfileMode && (
                <Switch
                  onChange={() => cogoToast.success('Updated User Archive Status')}
                  checked={!!isArchived}
                  height={14}
                  width={24}
                  onColor='#333'
                  offColor='#ccc'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              )}
            </div>
            <div className='clickable' onClick={() => (editProfileMode ? updatePhoneNumer() : copyToClipboard(phone, true))}>
              {phone || 'No Phone Number'}
              {editProfileMode ? <FontAwesomeIcon icon={faPencil} /> : <FontAwesomeIcon icon={faCopy} />}
            </div>
            <div className='clickable' onClick={() => (editProfileMode ? updateAdresss() : copyToClipboard(address, true))}>
              {address || 'No Address'}
              {editProfileMode ? <FontAwesomeIcon icon={faPencil} /> : <FontAwesomeIcon icon={faCopy} />}
            </div>
            <div className='edit-button' onClick={() => setEditProfileMode(!editProfileMode)}>
              {editProfileMode ? 'Stop Editing' : 'Edit'}
            </div>
          </div>
        </div>
      </div>
      <div className='concierge-sidebar-container controls'>
        <div className='controls-container'>
          <div className='controls-container-header'>Time Interval</div>
          <div className='time-interval-container'>
            <div onClick={() => updateGroupingMethod('week')} className={cn('time-interval', { selected: timePeriod.groupingMethod === 'week' })}>
              week
            </div>
            <div onClick={() => updateGroupingMethod('month')} className={cn('time-interval', { selected: timePeriod.groupingMethod === 'month' })}>
              month
            </div>
            <div
              onClick={() => updateGroupingMethod('quarter')}
              className={cn('time-interval', { selected: timePeriod.groupingMethod === 'quarter' })}
            >
              quarter
            </div>
          </div>
        </div>
        <div className='controls-container'>
          <div className='controls-container-header'>Start Date</div>
          <DatePicker
            selected={moment(timePeriod.startDate).toDate() || null}
            onChange={updateStartDate}
            wrapperClassName='date-picker-wrapper'
            className='date-picker'
            dateFormat={'MMM dd, yyyy'}
          />
        </div>
        <div className='controls-container'>
          <div className='controls-container-header'>End Date</div>
          <DatePicker
            selected={moment(timePeriod.endDate).toDate() || null}
            onChange={updateEndDate}
            wrapperClassName='date-picker-wrapper'
            className='date-picker'
            dateFormat={'MMM dd, yyyy'}
          />
        </div>
        <div className='controls-container'>
          <div className='controls-container-header'>General Notes</div>
          <div className='notes-container'>
            <div className='note-outer add-comment' onClick={() => createComment('general')}>
              +
            </div>
            {(notes || [])
              .filter(note => note.type === 'general' && !note.isDismissed)
              .sort((a, b) => a.isRead - b.isRead || b.updatedAt - a.updatedAt)
              .map(note => (
                <ConciergeComment comment={note} updateComment={updateComment} key={`concierge-modal-${note.Comment_id}`} />
              ))}
          </div>
        </div>
        <div className='controls-container'>
          <div className='controls-container-header'>Tasks</div>
          <div className='notes-container'>
            <div className='note-outer add-comment' onClick={() => createComment('task')}>
              +
            </div>
            {(notes || [])
              .filter(note => note.type === 'task' && !note.isDismissed)
              .sort((a, b) => b.updatedAt - a.updatedAt || a.isRead - b.isRead)
              .map(note => (
                <ConciergeComment comment={note} updateComment={updateComment} key={`concierge-modal-${note.Comment_id}`} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ConciergeModalSidebar.propTypes = {
  accountManagers: PropTypes.array.isRequired,
  talent: PropTypes.object.isRequired,
  setTimePeriod: PropTypes.func.isRequired,
  timePeriod: PropTypes.object.isRequired,
  updateComment: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserAddress: PropTypes.func.isRequired
};

export default ConciergeModalSidebar;
