import _ from 'lodash';
import {
  fetchVariantsQueue,
  fetchVariantsQueueFilters as fetchVariantsQueueFiltersAPI,
  fetchPinsQueue,
  fetchPinsQueueRemaining
} from '../api/queues';

export const GET_VARIANTS_QUEUE_SUCCESS = 'GET_VARIANTS_QUEUE_SUCCESS';
export const GET_VARIANTS_QUEUE_FILTERS_REQUEST = 'GET_VARIANTS_QUEUE_FILTERS_REQUEST';
export const GET_VARIANTS_QUEUE_FILTERS_SUCCESS = 'GET_VARIANTS_QUEUE_FILTERS_SUCCESS';
export const GET_PINS_QUEUE_SUCCESS = 'GET_PINS_QUEUE_SUCCESS';
export const GET_PINS_QUEUE_REMAINING_SUCCESS = 'GET_PINS_QUEUE_REMAINING_SUCCESS';

export const getVariantsQueue = (params = {}) => async (dispatch, getState) => {
  const resp = await fetchVariantsQueue(params);
  dispatch(getVariantsQueueFilters(params));
  return dispatch({
    type: GET_VARIANTS_QUEUE_SUCCESS,
    variants: _.get(resp, 'variants', [])
  });
};

export const getVariantsQueueFilters = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: GET_VARIANTS_QUEUE_FILTERS_REQUEST });
  const resp = await fetchVariantsQueueFiltersAPI(params);
  return dispatch({
    type: GET_VARIANTS_QUEUE_FILTERS_SUCCESS,
    domains: _.get(resp, 'domains', []),
    brands: _.get(resp, 'brands', []),
    retailers: _.get(resp, 'retailers', [])
  });
};

export const getPinsQueue = (params = {}) => async (dispatch, getState) => {
  const resp = await fetchPinsQueue(params);
  return dispatch({
    type: GET_PINS_QUEUE_SUCCESS,
    pins: _.get(resp, 'pins', [])
  });
};

export const getPinsQueueRemaining = () => async (dispatch, getState) => {
  const resp = await fetchPinsQueueRemaining();
  return dispatch({
    type: GET_PINS_QUEUE_REMAINING_SUCCESS,
    pinsRemaining: _.get(resp, 'num_remaining', 0)
  });
};
