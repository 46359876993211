import _ from 'lodash';
import commaNumber from 'comma-number';
import moment from 'moment';

export const getDomain = url =>
  _.first(_.last(_.split(url, '://')).split('/'))
    .split('www.')
    .join('');

export const prettyNumber = number =>
  Math.abs(number) > 999 ? Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + 'k' : Math.sign(number) * Math.abs(number);

export const formatAmountInCurrency = (amount, currency) => new Intl.NumberFormat('EN', { style: 'currency', currency }).format(amount);

export const getPrettyNumber = (numberRaw, options = {}) => {
  /*  
    Shorten the number with standard endings.
    
    1000000 => 1m
    124000 => 124k
    12500 => 12.5k
    1149 => 1.1k
    839 => 839
  */
  const { useAbsolute } = options;
  let number = useAbsolute ? Math.abs(numberRaw) : numberRaw;
  if (number > 1e6) {
    let millions = Math.floor(number / 1e6);
    let hundredthousands = Math.round((number % 1e6) / 1e5);
    if (hundredthousands === 10) {
      millions += 1;
      hundredthousands = 0;
    }
    return millions >= 100 ? `${millions}m` : `${millions}${hundredthousands ? `.${hundredthousands}` : ''}m`;
  } else if (number > 1e3) {
    let thousands = Math.floor(number / 1e3);
    let hundreds = Math.round((number % 1e3) / 1e2);
    if (hundreds === 10) {
      thousands += 1;
      hundreds = 0;
    }
    return thousands >= 100 ? `${thousands}k` : `${thousands}${hundreds ? `.${hundreds}` : ''}k`;
  }
  return number.toFixed(0);
};

export const getPrettyPriceDisplay = (priceRaw, options = {}) => {
  /*
    1.5 => $1.50
    2 => $2
  */
  const { perDay, precision, useUSD, useAbsolute } = options;
  if (!priceRaw && priceRaw !== 0) return '';
  const price = parseFloat(useAbsolute ? Math.abs(priceRaw) : priceRaw);
  const prettyNumber = commaNumber(price.toFixed(!_.isNil(precision) ? precision : price === Math.floor(price) ? 0 : 2));
  return `${useUSD ? '' : '$'}${prettyNumber}${useUSD ? ' USD' : ''}${perDay ? ' per day' : ''}`;
};

export const getPercentileNumber = number => {
  number = Number.parseInt(number) || 0;
  const last_digit_of_number = number % 10;

  switch (last_digit_of_number) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const getServerFormattedDate = (dateString = '') => moment(dateString || new Date()).format('YYYY-MM-DD HH:mm:ss');
export const getGMTTime = () => new Date(new Date().toGMTString()).getTime();

export const stringToReadableDate = ({ day = '01', month = '01', year = '2000', date, week }) => {
  const full_date = date ? moment(date) : moment(`${year}-${month}-${day}`);
  week && full_date.week(week);

  const [month_string, day_string] = full_date.format('MMMM dddd YYYY').split(' ');
  const [shortened_month_string] = full_date.format('MMM DD YYYY').split(' ');

  return {
    month: month_string,
    shortened_month: shortened_month_string,
    day_number: full_date.day(),
    day_of_week: day_string,
    day_of_month: full_date.date(),
    year,
    dayOfWeek: full_date.weekday,
    weekOfYear: full_date.weekYear,
    numDaysInMonth: full_date.daysInMonth
  };
};
